import { registerApplication, start } from "single-spa";

function CustomProps(props) {
    const config = typeof props === 'object' ? props : {};
    return {
        application: 'Erp'
        ,company: 'Mundo'
        ,...config
    }
}

registerApplication({
    name: "@mp/app-router"
    ,app: () => System.import("@mp/app-router")
    ,activeWhen: ["/"]
});

registerApplication({
    name: "@mp/app-login"
    ,app: () => System.import("@mp/app-login")
    ,activeWhen: ["/erp", "/login"]
    ,customProps: CustomProps()
});

registerApplication({
    name: "@mp/app-plataform"
    ,app: () => System.import("@mp/app-plataform")
    ,activeWhen: ["/erp"]
    ,customProps: CustomProps()
});

start({
  urlRerouteOnly: true,
});
